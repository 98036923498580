
<script>
import BVForm from '@/components/BVForm/BVForm.vue'
import CardTable from "@/components/cardTable/CardTable.vue";
import Swal from 'sweetalert2';
export default {
    name: "AdministradorDeVacantes",
    components: {
        BVForm,
        CardTable
    },
    data() {
        return {
            headers: [
                'Nombres y apellidos',
                'Identificación',
                'Fecha de postulación',
                'Ver postulación',
                'Talento interno',
                'Aprobado',
                'Opciones',
            ],

            listaDePostulantes: [],
            postulanteDatos: {},
            opcionesDeEvaluacion: [],


            evaluarSchema: [
                {
                    name: 'estadoEvaluacion',
                    type: 'select',
                    label: 'Estado de la evaluación en preselección',
                    options: []
                }
            ],
            resumenInCards: [
                {
                    title: "Aprobados",
                    value: () => this.listaDePostulantes.filter(postulante => postulante.preseleccionEvaluacion.estado == "Aprobado").length,
                    iconName: "circle-check",
                    color: "#16a275",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        window.$("#datatable_cargos").DataTable().search("Aprobado").draw()
                    },
                    onDeactivate: () => {
                        window.$("#datatable_cargos").DataTable().search("").draw()
                    },
                },
                {
                    title: "Pendientes",
                    value: () => this.listaDePostulantes.filter(postulante => postulante.preseleccionEvaluacion.estado == "Pendiente").length,
                    iconName: "clock",
                    color: "#fe721c",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        window.$("#datatable_cargos").DataTable().search("Pendiente").draw()
                    },
                    onDeactivate: () => {
                        window.$("#datatable_cargos").DataTable().search("").draw()
                    },
                },
                {
                    title: "Rechazados",
                    value: () => this.listaDePostulantes.filter(postulante => postulante.preseleccionEvaluacion.estado == "Rechazado").length,
                    iconName: "ban",
                    color: "rgba(223, 18, 46, 1)",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        window.$("#datatable_cargos").DataTable().search("Rechazado").draw()
                    },
                    onDeactivate: () => {
                        window.$("#datatable_cargos").DataTable().search("").draw()
                    },
                },
                {
                    title: "Total postulantes",
                    value: () => this.listaDePostulantes.length,
                    iconName: "users",
                    color: "rgba(71, 136, 255, 1)",
                    tipo: "squared",
                    animated: "bounce",
                    onActivate: () => {
                        window.$("#datatable_cargos").DataTable().search("").draw()
                    },
                    onDeactivate: () => {
                        window.$("#datatable_cargos").DataTable().search("").draw()
                    },
                }
            ]
        }
    },
    async mounted() {
        this.getListaDeVacantes();

        const response = await this.$store.getters.fetchGet({ path: 'ReclutamientoYSeleccion/Seleccion/estados' })
        const data = await response.json()
        this.opcionesDeEvaluacion = data.map(element => ({ id: element.id, descripcion: element.estado }))
        this.evaluarSchema[0].options = this.opcionesDeEvaluacion

        
    },
    methods: {
        async getListaDeVacantes() {
            const response = await this.$store.getters.fetchGet({ path: `Postulacion/postulantes/${this.$route.params.vacanteId}` })
            this.listaDePostulantes = await response.json();
        },
        openModalEvaluar(data) {
            this.postulanteDatos = data;
            this.$bvModal.show('modal-evaluar');
        },
        getIcon(value) {
            if (value == 'Pendiente') {
                return 'fas fa-clock text-warning'
            }
            else if (value == 'Aprobado') {
                return 'fas fa-check-circle text-success'
            }
            else if (value == 'Rechazado') {
                return 'fas fa-times-circle text-danger'
            }
        },
        evaluar(self) {
            self.loader = true

            this.$store.getters.fetchPut({
                path: `Postulacion/evaluar/preseleccion/${this.postulanteDatos.id}/${self.data.estadoEvaluacion}`,
                data: self.data
            })
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Error al guardar los datos personales')
                })
                .then(result => {
                    const index = this.listaDePostulantes.findIndex(item => item.id == result.id)
                    this.listaDePostulantes[index] = result
                    this.listaDePostulantes = [...this.listaDePostulantes]

                    Swal.fire({
                        title: 'Exito!',
                        text: 'Se ha guardado el estado de la evaluación',
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    })
                })
                .catch(() => {
                    Swal.fire({
                        title: 'Error!',
                        text: 'No se ha guardado el estado de la evaluación',
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    })
                })
                .finally(() => {
                    self.loader = false
                })
        }

    },
    filters: {
        esDeTalentoInterno(value) {
            return value ? 'Si' : 'No'
        },
    }
}


</script>

<template>
    <b-container fluid>
        <b-modal id="modal-evaluar" class="modal-sq" size="lg" hide-footer>
            <template #modal-header="{ close }">
                <div class="d-flex justify-content-between" style="width: 100%;">
                    <p class="mb-0 h4"><strong>Estado de la evaluación</strong></p>
                    <div @click="close()" class="btn btn-danger btn-sm btn-pm">
                        <i class="fas fa-times"></i>
                    </div>
                </div>
            </template>

            <BVForm :schema="evaluarSchema" :callBackSubmit="evaluar">

            </BVForm>

        </b-modal>
        <b-row>
            <b-col sm="12">
                <card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title"><b>Proceso de preselección</b></h4>
                    </template>
                    <div class="px-4">
                        <CardTable class="px-3" :data="resumenInCards"></CardTable>
                    </div>
                    <div class="table-responsive px-4 mt-3">
                        <table id="datatable_cargos"
                            class="table table-bordered table-responsive-md table-striped text-center">
                            <thead>
                                <tr>
                                    <th v-for="text in headers" :key="text + 'headers'">
                                        {{ text }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="listaDePostulantes.length == 0" :key="text + 'null'">
                                    <td v-for="text in headers" :key="text">
                                        Null
                                    </td>
                                </tr>

                                <tr v-for="postulante in listaDePostulantes" :key="postulante.id">
                                    <td>{{ postulante.nombres + ' ' + postulante.apellidos }}</td>
                                    <td>{{ postulante.numeroDocumento }}</td>
                                    <td>{{ $store.getters.useMoment(postulante.fechaDePostulacion) }}</td>
                                    <td>
                                        <router-link :to="{ name: 'RegistroDePostulacion', params: { id: postulante.id } }"
                                            class="btn btn-primary btn-sm">
                                            Ver más
                                        </router-link>
                                    </td>
                                    <td> {{ postulante.personaId | esDeTalentoInterno }} </td>
                                    <td class="d-flex justify-content-around">
                                        <i :class="getIcon(postulante.preseleccionEvaluacion.estado)"></i>
                                        {{ postulante.preseleccionEvaluacion.estado }}
                                    </td>
                                    <td class="options">
                                        <div class="btn btn-sm btn-primary" @click="openModalEvaluar(postulante)">
                                            Evaluar
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </card>
            </b-col>
        </b-row>
    </b-container>
</template>

<style scoped>
.btn-pm {
    position: relative;
    min-width: 20px;
    height: 20px;
    text-align: center;
    padding: 2px 5px;
    margin: 0px 2px;
    border: none;
    border-radius: 5px;
}

.btn-pm i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>